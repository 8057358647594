import { useEffect, useState } from 'react';

import { asFBReference } from '@lp-lib/firebase-typesafe';

import { getLogger } from '../../logger/logger';
import { assertExhaustive } from '../../utils/common';
import { createProvider } from '../../utils/createProvider';
import { type FirebaseService, useFirebaseContext } from '../Firebase';
import {
  type FBMsgPassStorage,
  recv,
  send,
} from '../Firebase/FirebaseMessagePassing';
import { useMyInstanceGetter } from '../Player';
import { useVenueId } from '../Venue';
import { makeLocalAudioOnlyVideoMixer } from './makeLocalAudioOnlyVideoMixer';
import { useGlobalSubtitlesManager } from './SubtitlesManagerProvider';
import { VariableRegistry } from './VariableRegistry';
import {
  type SubtitlesManager,
  VoiceOverRegistry,
  type VoiceOverRegistryPlan,
} from './VoiceOverRegistry';

const { Provider, useCreatedContext: useAdHocVOReceiver } =
  createProvider<AdHocReceiver>('AdHocReceiver');

const { Provider: SenderProvider, useCreatedContext: useAdHocVOSender } =
  createProvider<AdHocSender>('AdHocSender');

export { useAdHocVOSender, useAdHocVOReceiver };

export function useAdHocVOPlayerAPI() {
  const api = useAdHocVOReceiver();
  if (!api.api) throw new Error('No AdHocReceiver with VOPlayerAPI in tree');
  return api.api;
}

/**
 * Message Sender (does not send actual audio)
 */
export function AdHocVOMsgSenderProvider(props: {
  children?: React.ReactNode;
}) {
  const svc = useFirebaseContext().svc;
  const venueId = useVenueId();

  const [sender] = useState(() => {
    return new AdHocSender(svc, venueId);
  });

  return <SenderProvider value={sender}>{props.children}</SenderProvider>;
}

/**
 * Message Receiver (does not receive actual audio)
 */
export function AdHocVOMsgReceiverProvider(props: {
  children?: React.ReactNode;
  venueId: string;
}) {
  const svc = useFirebaseContext().svc;
  const getMyInstance = useMyInstanceGetter();
  const sub = useGlobalSubtitlesManager();

  const [receiver] = useState(() => {
    return new AdHocReceiver(new VOPlayerAPI(sub), svc, props.venueId);
  });

  useEffect(() => {
    receiver.api.syncDeps({
      getMyInstance,
    });
  }, [receiver, getMyInstance]);

  useEffect(() => {
    return () => {
      getLogger().scoped('AdHocVOPlayerProvider').info(`destroy api`);
      receiver.destroy();
    };
  }, [receiver]);

  return <Provider value={receiver}>{props.children}</Provider>;
}

function adHocVOMessageTransport(venueId: string, svc: FirebaseService) {
  const transport = asFBReference<FBMsgPassStorage>(
    svc.prefixedSafeRef(`adhoc-vo-msg/${venueId}`)
  );
  return transport;
}

type AdHocVOMessage =
  | {
      type: 'play';
      payload: {
        plan: VoiceOverRegistryPlan;
        playIf?: {
          teamIdIn?: string[];
          clientIdIn?: string[];
          teamIdNotIn?: string[];
          clientIdNotIn?: string[];
        };
      };
    }
  | {
      type: 'updateVariable';
      payload: {
        name: string;
        value: string;
      };
    };

class AdHocReceiver {
  off;

  constructor(
    public api: VOPlayerAPI,
    svc: FirebaseService,
    venueId: string,
    private transport = adHocVOMessageTransport(venueId, svc)
  ) {
    this.off = recv<AdHocVOMessage>(this.transport, async (req, _id) => {
      switch (req.type) {
        case 'play':
          return this.api.playScript(req.payload.plan, req.payload.playIf);

        case 'updateVariable':
          return this.api.updateVariable(req.payload.name, req.payload.value);

        default:
          assertExhaustive(req);
      }
    });
  }

  destroy() {
    this.off();
  }
}

class AdHocSender {
  constructor(
    svc: FirebaseService,
    venueId: string,
    private transport = adHocVOMessageTransport(venueId, svc)
  ) {}

  private async send(msg: AdHocVOMessage) {
    await send(this.transport, msg);
  }

  // NOTE: I replicated the API to be a little nicer for the UI, but it's not
  // necessary. All you really need is a single function with the known message
  // type.

  async playScript(
    plan: VoiceOverRegistryPlan,
    playIf?: {
      teamIdIn?: string[];
      clientIdIn?: string[];
      teamIdNotIn?: string[];
      clientIdNotIn?: string[];
    }
  ) {
    return this.send({
      type: 'play',
      payload: {
        plan: plan,
        playIf,
      },
    });
  }

  async updateVariable(name: string, value: string) {
    return this.send({
      type: 'updateVariable',
      payload: {
        name,
        value,
      },
    });
  }
}

/**
 * This API represents a local voice over player. It's a simplified one-off API
 * to combine the VoiceOverRegistry, VariableRegistry, and a VideoMixer.
 */
class VOPlayerAPI {
  private vor = new VoiceOverRegistry();
  private vrr = new VariableRegistry();
  private vm;

  deps: {
    getMyInstance?: ReturnType<typeof useMyInstanceGetter>;
  } = {};

  constructor(sub: SubtitlesManager) {
    const [vm] = makeLocalAudioOnlyVideoMixer();
    this.vm = vm;
    this.vor.setVideoMixer(this.vm);
    this.vor.setSubtitlesManager('local', sub);
  }

  syncDeps(deps: typeof this.deps) {
    this.deps = {
      ...this.deps,
      ...deps,
    };
  }

  async playScript(
    plan: VoiceOverRegistryPlan,
    playIf?: {
      teamIdIn?: string[];
      clientIdIn?: string[];
      teamIdNotIn?: string[];
      clientIdNotIn?: string[];
    }
  ) {
    const me = this.deps?.getMyInstance?.();

    if (!me) return;

    // TODO: probably worth while to export this to a helper function for reuse.
    if (playIf) {
      const { clientIdIn, clientIdNotIn, teamIdIn, teamIdNotIn } = playIf;
      if (clientIdIn && !clientIdIn.includes(me.clientId)) return;
      if (clientIdNotIn && clientIdNotIn.includes(me.clientId)) return;
      if (teamIdIn && me.teamId && !teamIdIn.includes(me.teamId)) return;
      if (teamIdNotIn && me.teamId && teamIdNotIn.includes(me.teamId)) return;
    }

    if (!this.vm.playing) this.vm.play();

    const group = await this.vor.getOrCreateGroup(plan);
    if (!group) return;

    const info = await group?.play(this.vrr);
    return info;
  }

  async registerGroup(plan: VoiceOverRegistryPlan) {
    const group = this.vor.getOrCreateGroup(plan);
    return (await group).id;
  }

  async updateVariable(name: string, value: string) {
    this.vrr.set(name, async () => value);
  }

  getRegisteredGroups() {
    return this.vor.getGroups();
  }

  async getKnownVariables() {
    return this.vor.getVariableNames();
  }

  async destroy() {
    this.vm.pause();
    this.vm.destroy();
    this.vrr.destroy();
    this.vor.destroy();
  }
}
