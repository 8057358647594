import { useEffect, useRef, useState } from 'react';

import {
  EnumsTTSGeneratorId,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';

import { elevenLabs } from '../../../config/elevenLabs';
import { getEnv } from '../../../config/getEnv';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { assertDefinedFatal } from '../../../utils/common';
import { useOndVideoMixer_UNSTABLE } from '../../Game/hooks';
import { PauseIcon } from '../../icons/PauseIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { XIcon } from '../../icons/XIcon';
import { useParticipantsAsArray } from '../../Player';
import { type VideoMixer } from '../../VideoMixer';
import {
  AdHocVOMsgSenderProvider,
  useAdHocVOPlayerAPI,
  useAdHocVOSender,
} from '../../VoiceOver/AdHocVOMsgProviders';
import { makeLocalAudioOnlyVideoMixer } from '../../VoiceOver/makeLocalAudioOnlyVideoMixer';
import { useGlobalSubtitlesManager } from '../../VoiceOver/SubtitlesManagerProvider';
import { VariableRegistry } from '../../VoiceOver/VariableRegistry';
import {
  type VoiceOverGroup,
  VoiceOverRegistry,
  type VoiceOverRegistryId,
} from '../../VoiceOver/VoiceOverRegistry';

const VMItems = ['none', 'local', 'ond'] as const;

function useVideoMixer() {
  const [target, setTarget] = useState<(typeof VMItems)[number]>('none');
  const ondVm = useOndVideoMixer_UNSTABLE();
  const [localVm, setLocalVm] = useState<null | VideoMixer>(null);

  useEffect(() => {
    if (target !== 'local') return;

    const [vm] = makeLocalAudioOnlyVideoMixer();
    vm.play();
    setLocalVm(vm);
    return () => {
      vm.pause();
      vm.destroy();
    };
  }, [target]);

  return [target === 'ond' ? ondVm : localVm, target, setTarget] as const;
}

export function AdHocVoiceOversPanel(): JSX.Element | null {
  const config = elevenLabs(getEnv());
  const [ids, setIds] = useState(() => new Set<VoiceOverRegistryId>());
  const [reg] = useState(() => new VoiceOverRegistry());
  const [vm, vmTarget, setTargetVm] = useVideoMixer();
  const prevVmSetting = useRef(vmTarget);
  const sub = useGlobalSubtitlesManager();

  const onSelectTarget = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const next = ev.target.value as (typeof VMItems)[number];
    if (next !== prevVmSetting.current) {
      reg.getGroups().forEach((entry) => {
        reg.forget(entry.id);
      });
    }
    setTargetVm(next);
    prevVmSetting.current = next;
  };

  useEffect(() => {
    reg.setVideoMixer(vm);
    reg.setSubtitlesManager('local', sub);
  }, [reg, sub, vm]);

  useEffect(() => {
    const aborter = new AbortController();

    reg.on(
      'registered',
      (id) => {
        setIds((ids) => new Set(ids).add(id));
      },
      { signal: aborter.signal }
    );

    reg.on(
      'forgotten',
      (id) => {
        setIds((ids) => {
          const newIds = new Set(ids);
          newIds.delete(id);
          return newIds;
        });
      },
      { signal: aborter.signal }
    );

    reg.on(
      'all-loaded',
      (id) => {
        setIds((ids) => new Set(ids).add(id));
      },
      {
        signal: aborter.signal,
      }
    );

    return () => {
      aborter.abort();
    };
  }, [reg]);

  const textRef = useRef<HTMLTextAreaElement | null>(null);
  const onAddScript = async () => {
    const value = textRef.current?.value;
    assertDefinedFatal(value);

    const group = await reg.getOrCreateGroup({
      entries: [
        {
          script: value,
          ttsRenderSettings: {
            generatorId: EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs,
            generatorSettings: {
              elevenLabs: {
                voiceId: config.defaultVoiceId,
                stability: 0.75,
                similarityBoost: 0.75,
              },
            },
          },
        },
      ],
    });

    await group.load(new VariableRegistry());
  };

  return (
    <div className='text-white flex flex-col gap-4'>
      <details className='text-2xs text-white'>
        <summary className='font-bold pb-2'>AdHoc VoiceOver Control</summary>

        <div className='flex flex-col items-center gap-2'>
          <label className='w-full self-start flex justify-between items-center'>
            <p>Choose VideoMixer Target</p>
            <select className='bg-transparent' onChange={onSelectTarget}>
              {VMItems.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
          <div className='text-3xs text-icon-gray'>
            <ul className='list-disc pl-3'>
              <li>none: do not attempt to push tracks into any videomixer.</li>
              <li>
                ond: attempt to push tracks into the ond videomixer. You only
                have access to this if you are the CONTROLLER of the game. If
                you are using a cloud host this will not work.
              </li>
              <li>
                local: attempt to push tracks into a local videomixer. This is
                useful for testing voiceovers without needing to be the
                controller.
              </li>
            </ul>
          </div>

          {ids.size ? (
            <>
              <header>VoiceOver Registry Entries </header>
              <table className='w-full table-fixed my-2'>
                <thead>
                  <tr style={{ visibility: 'collapse' }}>
                    <th className='font-normal w-1/12'></th>
                    <th className='font-normal'>Script...</th>
                    <th className='font-normal w-1/12'>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(ids).map((id) => {
                    const entry = reg.getGroups().get(id);
                    if (!entry) return null;
                    return <EntryRow key={id} entry={entry} reg={reg} />;
                  })}
                </tbody>
              </table>
            </>
          ) : null}

          <div className='w-full flex flex-col items-center gap-1'>
            <header>Add VoiceOver Entry</header>
            <textarea
              ref={textRef}
              className='w-full h-20 p-1 bg-secondary'
            ></textarea>
            <button
              type='button'
              className='btn-secondary w-full p-0.5 flex justify-center items-center'
              onClick={onAddScript}
            >
              Add Script
            </button>
          </div>
        </div>
      </details>
      <details className='text-2xs text-white'>
        <summary className='font-bold pb-2'>
          AdHoc Remote VoiceOver Control
        </summary>
        <AdHocVOMsgSenderProvider>
          <AdHocRemoteControl />
        </AdHocVOMsgSenderProvider>
      </details>
    </div>
  );
}

function AdHocRemoteControl() {
  const pAPI = useAdHocVOPlayerAPI();
  const config = elevenLabs(getEnv());

  const [groups, setGroups] = useState<VoiceOverGroup[]>([]);
  const [variables, setVariables] = useState<string[]>([]);
  const scriptRef = useRef<HTMLTextAreaElement | null>(null);

  // These two sync-ers are just because it wasn't worth building an entire
  // evented api for this debug tool

  const syncScripts = useLiveCallback(async () => {
    const s = await pAPI.getRegisteredGroups();
    setGroups(Array.from(s.values()));
    return s;
  });

  const syncVariables = useLiveCallback(async () => {
    const v = (await pAPI.getKnownVariables()) ?? [];
    setVariables(v);
    return v;
  });

  useEffect(() => {
    // sync on mount
    syncScripts();
    syncVariables();
  }, [syncScripts, syncVariables]);

  return (
    <div className='flex flex-col items-center gap-2'>
      <header>VARIABLES</header>
      {variables.map((variable, idx) => (
        <AHVariableEntry key={idx} name={variable} />
      ))}

      <header>SCRIPTS</header>
      {groups.map((script, idx) => (
        <AHScriptGroup key={idx} group={script} />
      ))}

      <div className='w-full'>
        <textarea
          ref={scriptRef}
          className='w-full h-20 p-1 bg-secondary'
        ></textarea>
        <button
          type='button'
          className='w-full btn-secondary px-2 py-1'
          onClick={async () => {
            await pAPI.registerGroup({
              entries: [
                {
                  script: scriptRef.current?.value ?? '',
                  ttsRenderSettings: {
                    generatorId: EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs,
                    generatorSettings: {
                      elevenLabs: {
                        voiceId: config.defaultVoiceId,
                        stability: 0.75,
                        similarityBoost: 0.75,
                      },
                    },
                  },
                  policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
                },
              ],
            });

            await syncScripts();
            await syncVariables();
          }}
        >
          Add Script
        </button>
      </div>
    </div>
  );
}

function AHVariableEntry(props: { name: string }) {
  const sender = useAdHocVOSender();
  const [value, setValue] = useState('');

  const onValueChanged = async () => {
    await sender.updateVariable(props.name, value);
  };

  return (
    <div className='w-full flex justify-between items-center gap-2'>
      <label>{props.name}</label>
      <input
        type='text'
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        className='p-1 bg-secondary'
      />
      <button
        type='button'
        className='w-1/3 btn-secondary px-2 py-1'
        onClick={onValueChanged}
      >
        Set
      </button>
    </div>
  );
}

function AHScriptGroup(props: { group: VoiceOverGroup }) {
  const sender = useAdHocVOSender();

  const everybody = useParticipantsAsArray({
    filters: ['status:connected', 'host:false'],
  });

  const [target, setTarget] = useState<string>('all');

  const onClickPlay = async () => {
    const playIf = target === 'all' ? undefined : { clientIdIn: [target] };

    // Instruct all valid playIfs to play, including, optionally, yourself. NOTE
    // that it's not easy to know when the audio has stopped because it is
    // happening on multiple clients.

    await sender.playScript(props.group.plan, playIf);
  };

  return (
    <div className='w-full flex justify-between items-center'>
      <button
        type='button'
        className='btn p-0.5 flex justify-center '
        onClick={onClickPlay}
      >
        <PlayIcon />
      </button>
      <div className='w-1/3'>
        {props.group.plan.entries.map((entry, idx) => (
          <span key={idx}>{'script' in entry ? entry.script : null}</span>
        ))}
      </div>
      <select
        className='bg-secondary'
        onChange={(e) => setTarget(e.currentTarget.value)}
        defaultValue={target}
      >
        <option value='all'>Everyone</option>
        {everybody.map((participant) => (
          <option key={participant.clientId} value={participant.clientId}>
            {participant.firstName ?? participant.username} (
            {participant.clientId.slice(0, 6)}...)
          </option>
        ))}
      </select>
    </div>
  );
}

function EntryRow(props: { entry: VoiceOverGroup; reg: VoiceOverRegistry }) {
  const [playing, setPlaying] = useState(false);
  const [playPauseDisabled, setPlayPauseDisabled] = useState(
    props.entry.atLeastOneLoaded()
  );

  useEffect(() => {
    props.entry
      .load(new VariableRegistry())
      .then(() => setPlayPauseDisabled(false));
  }, [props.entry]);

  const onPlayPause = async () => {
    if (playing) {
      await props.entry.stop();
      setPlaying(false);
      return;
    }

    setPlaying(true);
    const info = await props.entry.play(new VariableRegistry());
    await Promise.race([info?.trackEnded, info?.trackRemoved]);
    setPlaying(false);
  };

  return (
    <tr>
      <td className='flex justify-start'>
        <button
          type='button'
          disabled={playPauseDisabled}
          onClick={onPlayPause}
          className='disabled:text-icon-gray disabled:opacity-25'
        >
          {playing ? (
            <PauseIcon className='text-current' />
          ) : (
            <PlayIcon className='text-current' />
          )}
        </button>
      </td>
      <td>
        <div className='truncate'>
          {props.entry.plan.entries.map((e) =>
            'script' in e ? e.script : '(media)'
          )}
        </div>
      </td>
      <td className='flex justify-end'>
        <button
          type='button'
          onClick={() => {
            props.reg.forget(props.entry.id);
          }}
        >
          <XIcon />
        </button>
      </td>
    </tr>
  );
}
