import { type ReactNode, useEffect } from 'react';
import ReactSlider from 'react-slider';

import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { useMyInstance } from '../../hooks/useMyInstance';
import logger from '../../logger/logger';
import { ClientTypeUtils } from '../../types/user';
import { useReceivedIsLiveGamePlay } from '../Game/hooks';
import { GameModeIcon } from '../icons/GameModeIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { useParticipantFlags } from '../Player';
import { StageMode, useStageMode } from '../Stage';
import { useModeAwareAudienceRTCService, useTownhallConfig } from '../Townhall';
import {
  useGameBGMScale,
  useGameTeamVolumeBalancerValue,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useRTCService } from '../WebRTC';
import { HostAudioMixerPanel } from './HostAudioMixerPanel';

const log = logger.scoped('volume-balancer');

const VolumeBalancerBase = ({
  top,
  down,
  value,
  onChange,
  min = 0,
  max = 100,
  disabled,
  displayHandleValue = false,
}: {
  top?: ReactNode;
  down?: ReactNode;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
  displayHandleValue?: boolean;
}): JSX.Element => {
  return (
    <div
      className={`bg-lp-green-002 border border-secondary rounded-xl flex flex-col gap-2 justify-center items-center text-white text-3xs p-2 m-2 h-50 ${
        disabled ? 'pointer-events-none opacity-40' : ''
      }`}
    >
      <div className={`${value === min ? 'opacity-40' : ''}`}>{top}</div>
      <div className='flex-grow'>
        <ReactSlider
          className='w-8 h-full flex flex-col items-center select-none'
          trackClassName='volume-balancer-slider-track'
          thumbClassName='volume-balancer-slider-thumb text-4xs flex justify-center items-center'
          renderThumb={(props, state) => (
            <div {...props} key={props.key}>
              {displayHandleValue ? max - state.valueNow : null}
            </div>
          )}
          min={min}
          max={max}
          value={max - value}
          orientation='vertical'
          step={4}
          onChange={(val) => {
            const inverted = max - val;
            onChange(inverted);
          }}
        />
      </div>
      <div className={`${value === max ? 'opacity-40' : ''}`}>{down}</div>
    </div>
  );
};

function useVolumeController(enabled: boolean) {
  const [gameTeamVolumeBalancerValue] = useGameTeamVolumeBalancerValue();
  const [gameBGMScale] = useGameBGMScale();

  const audienceRTCService = useModeAwareAudienceRTCService();
  const stageRTCService = useRTCService('stage');
  const gameRTCService = useRTCService('game');
  const ondRTCService = useRTCService('ond');
  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);
  const stageMode = useStageMode();
  const townhallMode = useTownhallConfig().mode;
  const isLive = useReceivedIsLiveGamePlay();

  useEffect(() => {
    if (!enabled) return;
    // historically we mute team volume when the user is on stage. however, the
    // new icebreaker blocks bring users on stage and we want them to still
    // hear.
    const isOnStageInBlockControlledMode =
      flags?.onStage &&
      stageMode === StageMode.BLOCK_CONTROLLED &&
      townhallMode === 'crowd';
    const shouldMute = flags?.onStage && !isOnStageInBlockControlledMode;
    const shouldUseTeamVolumeForStage = !isLive && !shouldMute;
    const teamVolume = shouldMute ? 0 : 100 - gameTeamVolumeBalancerValue;
    if (audienceRTCService) {
      audienceRTCService.setRemoteUserVolume(teamVolume);
    }
    const ondBoostFactor =
      getFeatureQueryParamNumber('game-on-demand-onstage-volume-boost') / 100;
    const boostedStageVolume = clamp(teamVolume * ondBoostFactor, 0, 100);
    const targetVolume = gameTeamVolumeBalancerValue;
    stageRTCService.setRemoteUserVolume(
      shouldUseTeamVolumeForStage ? boostedStageVolume : targetVolume
    );
    ondRTCService.setRemoteUserVolume(targetVolume);
    gameRTCService.setRemoteUserVolume(targetVolume * (gameBGMScale ?? 1));

    log.info('changed', {
      host: targetVolume,
      team: teamVolume,
      game: targetVolume * (gameBGMScale ?? 1),
    });
  }, [
    audienceRTCService,
    stageRTCService,
    gameTeamVolumeBalancerValue,
    gameRTCService,
    ondRTCService,
    gameBGMScale,
    enabled,
    stageMode,
    townhallMode,
    flags?.onStage,
    isLive,
  ]);
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

const GameTeamVolumeBalancer = (props: { showTeam: boolean }): JSX.Element => {
  const [gameTeamVolumeBalancerValue, setGameTeamVolumeBalancerValue] =
    useGameTeamVolumeBalancerValue();

  const top = (
    <div className='flex flex-col items-center'>
      <GameModeIcon className='w-3.5 h-3.5 fill-current' />
      <span>Game</span>
    </div>
  );
  const down = props.showTeam ? (
    <div className={`flex flex-col items-center`}>
      <TeamIcon className='w-3.5 h-3.5 fill-current' />
      <span>Team</span>
    </div>
  ) : null;

  return (
    <VolumeBalancerBase
      top={top}
      down={down}
      value={gameTeamVolumeBalancerValue}
      onChange={setGameTeamVolumeBalancerValue}
    />
  );
};

export const VolumeBalancerContainer = ({
  isOpen,
  showTeam,
}: {
  isOpen: boolean;
  showTeam?: boolean;
}): JSX.Element | null => {
  const isAudience = ClientTypeUtils.isAudience(useMyClientType());
  useVolumeController(isAudience);

  if (!isOpen) return null;
  return (
    <div className={`absolute -bottom-14 left-14 flex items-end`}>
      <GameTeamVolumeBalancer showTeam={!!showTeam} />

      {!isAudience && <HostAudioMixerPanel />}
    </div>
  );
};
