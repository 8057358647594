import React, { type ComponentPropsWithoutRef, forwardRef } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

export const Menu = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={`
          border border-secondary rounded-lg
          text-white flex flex-col p-1 z-50
          transition-opacity bg-dark-gray whitespace-nowrap
        `}
      >
        <div className='flex flex-col gap-1'>{children}</div>
      </div>
    );
  }
);

export function MenuItem(props: {
  text: React.ReactNode;
  icon?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}) {
  const { icon, text, onClick } = props;
  return (
    <button
      className={`
        btn w-full h-8 px-2 hover:bg-light-gray rounded-lg
        flex items-center gap-2 text-2xs cursor-pointer
      `}
      onClick={onClick}
      type='button'
      disabled={props.disabled}
    >
      {icon}
      {text}
    </button>
  );
}

export function NestedMenuItem(props: {
  icon: React.ReactNode;
  text: string;
  disabled?: boolean;
  children?: React.ReactNode;
}) {
  const { icon, text, disabled, children } = props;

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'hover',
      placement: 'right-start',
      interactive: true,
      delayHide: 50,
    });

  return (
    <>
      <div
        ref={setTriggerRef}
        className={`
          btn w-full h-8 px-2 hover:bg-light-gray rounded-lg
          flex items-center gap-2 text-2xs cursor-pointer
          ${disabled ? 'opacity-30 pointer-events-none' : ''}
        `}
      >
        {icon}
        {text}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `
              border border-secondary rounded-lg min-w-40
              text-white flex flex-col p-1 z-50
              transition-opacity bg-dark-gray whitespace-nowrap
            `,
          })}
        >
          <div className='flex flex-col gap-1'>{children}</div>
        </div>
      )}
    </>
  );
}
